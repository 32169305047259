<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { debounce } from "lodash";
export default {
  name: 'App',
  data() {
    return {
      transitionName: "",
      isEnd: false,
      isStart: false,
      flagTop: 0,
      flagBottom: 0,
      scrollTop: 0,
      clientHeight: 0,
      scrollHeight: 0,
      numHeight: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // window.addEventListener('scroll', this.checkScroll);
      window.addEventListener('mousewheel', this.handleWheel);
      // this.checkScroll()
    })
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.checkScroll, true);
    window.removeEventListener('mousewheel', this.handleWheel, true);
  },
  methods: {
    checkScroll() {
      // let scrollTop =
      //   document.documentElement.scrollTop || document.body.scrollTop;
      // let clientHeight =
      //   document.documentElement.clientHeight || document.body.clientHeight;
      // let scrollHeight =
      //   document.documentElement.scrollHeight || document.body.scrollHeight;
      // let numHeight = scrollTop + clientHeight;
      // scrollHeight----元素总高度，clientHeight----当前浏览器可展示高度，numHeight----浏览器展示元素的高度位置，scrollTop----滚动条距离顶部位置
      // if (scrollHeight >= clientHeight && numHeight >= scrollHeight) {
      //   this.isEnd = true
      // } else {
      //   this.isEnd = false
      //   this.flag = 0
      // }
      // 判断是否有滚动条
    },

    handleWheel: debounce(function (event) {
      this.doSomething(event);
    }, 300),

    doSomething(event) {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条 
      this.clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
      this.scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      this.numHeight = this.scrollTop + this.clientHeight;
      if (this.scrollHeight > this.clientHeight) {
        // 滚动条倒地
        if (this.numHeight >= this.scrollHeight) {
          this.isEnd = true
        } else if (this.scrollTop === 0) {
          this.isStart = true
        } else {
          this.isEnd = false
          this.isStart = false
        }
      } else {
        // 没有滚动条
        this.isEnd = true
        this.isStart = true
        this.flagTop = 1
        this.flagBottom = 1
      }
      if (this.isEnd && event.deltaY > 0) {
        if (this.flagBottom === 0) {
          this.flagBottom = this.flagBottom + 1
          this.flagTop = 0
          return
        }
      } else if (this.isStart && event.deltaY < 0) {
        if (this.flagTop === 0) {
          this.flagTop = this.flagTop + 1
          this.flagBottom = 0
          return
        }
      } else {
        this.flagTop = 0
        this.flagBottom = 0
      }
      if (this.isEnd && event.deltaY > 0 && this.flagBottom > 0) {
        const routerList = this.$router.options.routes[0].children
        for (let i = 0; i < routerList.length; i++) {
          if (Number(this.$route.meta.index) + 1 === routerList[i].meta?.index) {
            this.$router.push({
              path: routerList[i].path,
            })
            break
          }
        }
      } else if (this.isStart && event.deltaY < 0 && this.flagTop > 0) {
        const routerList = this.$router.options.routes[0].children
        for (let i = 0; i < routerList.length; i++) {
          if (Number(this.$route.meta.index) - 1 === routerList[i].meta?.index) {
            this.$router.push({
              path: routerList[i].path,
            })
            break
          }
        }
      }
    }
  },
  components: {
  },
  watch: {
    $route(to, from) {
      this.flagTop = 0
      this.flagBottom = 0
      //实现路由跳转动画
      if (to.meta.index > from.meta.index)
        this.transitionName = "slide-left";
      if (to.meta.index < from.meta.index)
        this.transitionName = "slide-right";
    }
  }
}
</script>

<style lang="scss">
#app {
  min-width: 1440px;
  // min-height: 100vh;
  // height: 100vh;
  // 不展示滚动条,但是可以滚动
  /* ::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
} */
  /* 路由页面跳转交互 */
  .slide-right-enter-active,
  .slide-right-leave-active,
  .slide-left-enter-active,
  .slide-left-leave-active {
    will-change: transform;
    transition: all 0.3s;
    position: absolute;
  }
  .slide-right-enter {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  .slide-right-leave-active {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  .slide-left-enter {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  .slide-left-leave-active {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
</style>
