import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: "index",
    component: () => import("@/views/index.vue"),
    children: [
      {
        name: "home",
        path: "/home",
        component: () => import("@/views/home"),
        meta: {
          index: 1
        }
      },
      {
        name: "about",
        path: "/about",
        component: () => import("@/views/about"),
        meta: {
          index: 2
        }
      },
      {
        name: "culture",
        path: "/culture",
        component: () => import("@/views/culture"),
        meta: {
          index: 3
        }
      },
      {
        path: '/dynamic',
        name: 'dynamic',
        component: () => import('@/views/dynamic'),
        meta: {
          index: 4
        }
      },
      {
        path: '/advantage',
        name: 'advantage',
        component: () => import('@/views/advantage'),
        children: [
          {
            name: "programme",
            path: "/advantage/programme",
            component: () => import("@/views/advantage/programme"),
            meta: {
              index: 5
            }
          },
          {
            name: "power",
            path: "/advantage/power",
            component: () => import("@/views/advantage/power"),
            meta: {
              index: 5
            }
          },
          {
            path: "/",
            redirect: "/advantage/programme",
          },
        ],
        meta: {
          index: 5
        }
      },
      {
        path: '/trends',
        name: 'trends',
        component: () => import('@/views/trends'),
        meta: {
          index: 6
        }
      },
      {
        path: '/training',
        name: 'training',
        component: () => import('@/views/training'),
        children: [
          {
            name: "charm",
            path: "/training/charm",
            component: () => import("@/views/training/charm"),
            meta: {
              index: 7
            }
          },
          {
            name: "school",
            path: "/training/school",
            component: () => import("@/views/training/school"),
            meta: {
              index: 7
            }
          },
          {
            name: "society",
            path: "/training/society",
            component: () => import("@/views/training/society"),
            meta: {
              index: 7
            }
          },
          {
            path: "/",
            redirect: "/training/charm",
          },
        ],
        meta: {
          index: 7
        }
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/contact'),
        meta: {
          index: 8
        }
      },
      {
        path: "/",
        redirect: "/home",
      },
    ],
  },
  {
    name: "dynamicDetail",
    path: "/dynamic-detail",
    component: () => import("@/views/dynamic/detail"),
  },
  {
    name: "trendsDetail",
    path: "/trends-detail",
    component: () => import("@/views/trends/detail"),
  },
  {
    name: "charmDetail",
    path: "/charm-detail",
    component: () => import("@/views/training/charmDetail"),
  },
  {
    name: "graceDetail",
    path: "/grace-detail",
    component: () => import("@/views/training/graceDetail"),
  },
  {
    path: "/",
    redirect: "/home",
  },
]

const router = new VueRouter({
  routes
})

export default router
